// import { number } from "prop-types";
import {
  ADD_TO_CART,
  CLEAR_CART,
  LOADED,
  LOADING,
  UPDATE_CART_QUANTITY,
  REMOVE_FROM_CART,
  UPDATE_VARIANT_CART_QUANTITY,
} from "./constants";

const initialState = {
  loading: false,
  cart: [],
};

export default function settings(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
      };
    case ADD_TO_CART:
      return {
        ...state,
        cart: payload,
      };
    case UPDATE_CART_QUANTITY:
      return {
        ...state,
        cart: state.cart.map((items) =>
          items._id === payload.productId
            ? { ...items, qty: payload.quantity }
            : items
        ),
      };
    case UPDATE_VARIANT_CART_QUANTITY:
      return {
        ...state,
        cart: state.cart.map((items) =>
          items._id === payload.productId &&
          items.variantId === payload.variantId
            ? { ...items, qty: payload.quantity }
            : items
        ),
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: payload,
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: initialState.cart,
      };
    default:
      return state;
  }
}
